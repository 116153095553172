<template>
  <v-container id="user" fluid tag="section">
    <v-snackbar v-model="updateCharges" :timeout="4000" top>
      <span>Delivery Charges Update Successfully</span>
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="updateCharges = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
    <v-snackbar v-model="updatestoreTimings" :timeout="4000" top>
      <span>Store Timings Updated Successfully</span>
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="updatestoreTimings = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
    <v-snackbar v-model="updateChargesRushHours" :timeout="4000" top>
      <span>Delivery Charges Per Rush Hours Update Successfully</span>
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="updateChargesRushHours = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
    <v-snackbar v-model="updateChargesRushHours" :timeout="4000" top>
      <span>Delivery Charges Per Rush Hours Update Successfully</span>
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="updateChargesRushHours = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
    <v-snackbar v-model="updateDeliveryOffers" :timeout="4000" top>
      <span>Delivery Offers Update Successfully</span>
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="updateDeliveryOffers = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
    <v-row>
      <v-col cols="4" md="4">
        <LowVanInventory />
      </v-col>
      <v-col cols="4" md="4">
        <OrderTax />
      </v-col>
      <v-col cols="4" md="4">
        <v-container id="deliverycharges" fluid tag="section">
          <v-card>
            <v-card-title> <h3>Default Delivery Charges</h3></v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-form ref="deliVeryCharges">
                    <v-container class="py-2">
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            type="number"
                            value="0.00"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                            label="Delivery Charges"
                            class="purple-input"
                            v-model="delivery_charges"
                            :rules="deliveryRule"
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" class="text">
                          <v-btn
                            type="button"
                            color="primary"
                            class="mr-0 float-right"
                            @click="submitDeliveryCharges"
                            :loading="deliveryCharges"
                            >Update</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-container>
      </v-col>
      <v-col cols="6" md="6">
        <v-container id="deliverycharges" fluid tag="section">
          <v-card>
            <v-card-title> <h3>Tipalti Authorization </h3></v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-form ref="deliVeryCharges">
                    <v-container class="py-2">
                      <v-row>
                        <v-col cols="12">
                          <v-card-text>
                            <p>In case the refresh token expires, please click the button below to reauthorize the application. This ensures continuous access to Tipalti services.</p>
                            <p v-if="refreshTokenExpired" style="color: red; font-family:serif; font-size: 1.2em; font-weight: bold; text-align: center;">refreshToken is expired, please reauthorize</p>
                          </v-card-text>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" class="text">
                          <v-btn
                            type="button"
                            color="primary"
                            class="m-auto float-end"
                            :disabled="refreshTokenExpire"
                            @click="tipaltiAuthRedirectUrl"
                            :loading="deliveryCharges"
                          >Reauthorize Tipalti</v-btn>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-container>
      </v-col>
      <!-- <v-col cols="6" md="6">
        <v-card>
          <v-card-title><h3>Delivery charge per Mile</h3></v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <v-form ref="deliVeryChargesPerMile">
                  <v-container class="py-2">
                    <v-row>
                      <v-col cols="6" md="6">
                        <v-text-field
                          label="Delivery Charges Per Mile"
                          class="purple-input"
                          v-model="delivery_charges_per_mile"
                          :rules="deliveryRule"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="text">
                        <v-btn
                          type="button"
                          color="primary"
                          class="mr-0"
                          @click="submitDeliveryChargesPerMile"
                          :loading="loadingPerMile"
                          >Update</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col> -->
    </v-row>
    <v-container id="storetimings" fluid tag="section">
      <v-card>
        <v-card-title><h3>Store Timings</h3></v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-radio-group v-model="selectedStore" row>
                <v-radio
                  v-for="(store, index) in stores"
                  :key="index"
                  :label="store.name"
                  :value="store"
                ></v-radio>
              </v-radio-group>
              <v-form ref="storetimings">
                <v-container class="py-2" v-if="storetimings != null">
                  <v-row v-for="(timeItem, index) in storetimings.value" :key="index">
                    <v-row v-if="selectedStore._id == timeItem.store_id"> 
                    <v-col cols="3" md="3">
                      <v-text-field
                        label="Select Day"
                        item-text="days"
                        item-value="days"
                        v-model="timeItem.day"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="3" md="3">
                      <v-menu
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="time"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            label="Start time"
                            prepend-icon="mdi-clock-time-four-outline"
                            v-model="timeItem.start_time"
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          ampm-in-title
                          v-model="timeItem.start_time"
                          :max="timeItem.end_time"
                          scrollable
                        ></v-time-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="3" md="3">
                      <v-menu
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="time"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="timeItem.end_time"
                            label="End time"
                            prepend-icon="mdi-clock-time-four-outline"
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          ampm-in-title
                          v-model="timeItem.end_time"
                          :min="timeItem.start_time"
                          scrollable
                        ></v-time-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="2" md="2">
                      <v-switch
                        v-model="timeItem.is_open"
                        color="rgb(45,201,55)"
                        flat
                        :label="timeItem.is_open ? 'Open' : 'Closed'"
                      ></v-switch>
                    </v-col>
                    </v-row>
                  </v-row>

                  <v-row>
                    <v-col cols="12" class="text">
                      <v-btn
                        type="button"
                        color="primary"
                        class="mr-0 float-right"
                        @click="updateTime"
                        >Update</v-btn
                      >
                    </v-col>
                  </v-row>
                  <v-dialog v-model="deleteRushHoursDialog" max-width="500px">
                    <v-card>
                      <v-card-title
                        >Are you sure you want to Delete Rush Hours?</v-card-title
                      >
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="deleteRushHoursDialog = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="deleteItemConfirmRushHours"
                          >Delete</v-btn
                        >
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-container>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
    <v-container id="deliverycharges" fluid tag="section">
      <v-card>
        <v-card-title> <h3>Rush Hours Delivery Charges</h3></v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-form ref="deliVeryChargesOnRushHours">
                <v-container class="py-2">
                  <v-row
                    v-for="(num, index) in deliveryChargesonRushHoursNumber"
                    :key="num"
                  >
                    <v-col cols="3" md="3">
                      <v-select
                        :items="days"
                        label="Select Day"
                        item-text="days"
                        item-value="days"
                        v-model="day[index]"
                      ></v-select>
                    </v-col>
                    <v-col cols="3" md="3">
                      <v-menu
                        v-model="onRushHoursStart[index]"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="time"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="deliVeryChargesOnRushHoursStart[index]"
                            label="Picker Start Time"
                            prepend-icon="mdi-clock-time-four-outline"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          ampm-in-title
                          v-if="onRushHoursStart[index]"
                          v-model="deliVeryChargesOnRushHoursStart[index]"
                          :max="deliVeryChargesOnRushHoursEnd[index]"
                          scrollable
                        ></v-time-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="3" md="3">
                      <v-menu
                        v-model="onRushHoursEnd[index]"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="time"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="deliVeryChargesOnRushHoursEnd[index]"
                            label="Picker End Time"
                            prepend-icon="mdi-clock-time-four-outline"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          ampm-in-title
                          v-if="onRushHoursEnd[index]"
                          v-model="deliVeryChargesOnRushHoursEnd[index]"
                          :min="deliVeryChargesOnRushHoursStart[index]"
                          scrollable
                        ></v-time-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="2" md="2">
                      <v-text-field
                        type="number"
                        value="0.00"
                        class="purple-input"
                        label="Delivery Charges"
                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                        v-model="deliveryCharge[index]"
                        :rules="deliveryRule"
                      />
                    </v-col>
                    <v-col cols="1" md="1">
                      <v-icon class="mt-6 ml-4" @click="deleteRushHours(index)"
                        >mdi-delete</v-icon
                      >
                    </v-col>
                  </v-row>
                  <v-card-actions>
                    <v-row>
                      <v-col cols="12" class="text-right">
                        <v-btn
                          outlined
                          text
                          type="button"
                          color="primary"
                          class="mr-0"
                          @click="addMoreDeliveryChargesOnRushHours"
                          ><v-icon>mdi-plus </v-icon>Add Rush Hours</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-card-actions>
                  <v-row>
                    <v-col cols="12" class="text">
                      <v-btn
                        type="button"
                        color="primary"
                        class="mr-0 float-right"
                        @click="submitDeliveryChargesOnRushHours"
                        :loading="loadingOnRushHours"
                        >Update</v-btn
                      >
                    </v-col>
                  </v-row>
                  <v-dialog v-model="deleteRushHoursDialog" max-width="500px">
                    <v-card>
                      <v-card-title
                        >Are you sure you want to Delete Rush Hours?</v-card-title
                      >
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="deleteRushHoursDialog = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="deleteItemConfirmRushHours"
                          >Delete</v-btn
                        >
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-container>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
    <v-container id="deliverycharges" fluid tag="section">
      <v-card>
        <v-card-title> <h3>Offers On Delivery Charges</h3></v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-form ref="deliVeryOffers">
                <v-container class="py-2">
                  <v-row v-for="(num, index) in deliveryOffersNumber" :key="num">
                    <v-col cols="4" md="4">
                      <v-text-field
                        type="number"
                        value="0.00"
                        label="Minimum Order Value"
                        class="purple-input"
                        @keypress="isPriceKey"
                        v-model="minOrderValue[index]"
                      />
                    </v-col>
                    <v-col cols="4" md="4">
                      <v-text-field
                        type="number"
                        value="0.00"
                        label="Delivery Charges in Amount"
                        class="purple-input"
                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                        v-model="deliveryOffersDeliveryChargeInAmount[index]"
                      />
                    </v-col>
                    <v-col cols="1" md="1">
                      <v-icon class="mt-6 ml-4" @click="deleteDeliveryOffer(index)"
                        >mdi-delete</v-icon
                      >
                    </v-col>
                  </v-row>
                  <v-card-actions>
                    <v-row>
                      <v-col cols="12" class="text-right">
                        <v-btn
                          outlined
                          text
                          type="button"
                          color="primary"
                          class="mr-0"
                          @click="addMoreDeliveryOffers"
                          ><v-icon>mdi-plus </v-icon>Add Delivery Offers</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-card-actions>
                  <v-row>
                    <v-col cols="12" class="text">
                      <v-btn
                        type="button"
                        color="primary"
                        class="mr-0 float-right"
                        @click="submitDeliveryOffers"
                        :loading="loadingDeliVeryOffers"
                        >Update</v-btn
                      >
                    </v-col>
                  </v-row>
                  <v-dialog v-model="deleteDeliveryOfferDialog" max-width="500px">
                    <v-card>
                      <v-card-title>Are you sure you want to Delete Offer?</v-card-title>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="deleteDeliveryOfferDialog = false"
                          >Cancel</v-btn
                        >
                        <v-btn color="blue darken-1" text @click="deleteItemConfirmOffer"
                          >Delete</v-btn
                        >
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-container>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
  </v-container>
</template>

<script>
export default {
  components: {
    LowVanInventory: () => import("./LowVanInventory"),
    OrderTax: () => import("./OrderTax"),
  },
  data() {
    return {
      days: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ],
      deliveryCharges: false,
      loadingRegister: false,
      deliveryChargesID: "",
      delivery_charges: "",
      updateCharges: false,
      delivery_charges_per_mile: "",
      deliveryChargesPerMileID: "",
      loadingPerMile: false,
      updateChargesPerMile: false,
      deliveryChargesonRushHoursNumber: [],
      arrayNumber: 1,
      day: [],
      colorchange: "",
      deliVeryChargesOnRushHoursStart: [],
      deliVeryChargesOnRushHoursEnd: [],
      onRushHoursStart: [],
      onRushHoursEnd: [],
      deliveryCharge: [],
      storetimingsEnd: [],
      storetimingsStart: [],
      storetimings: null,
      refreshTokenExpired: false,
      refreshTokenExpire : false,
      loadingOnRushHours: false,
      updatestoreTimings: false,
      deliveryChargesOnRushHoursID: "",
      updateChargesRushHours: false,
      deleteRushHoursDialog: false,
      deleteRushHoursItem: "",
      deliveryOffersNumber: [],
      minOrderValue: [],
      deliveryOffersDeliveryChargeInPercentage: [],
      deliveryOffersDeliveryChargeInAmount: [],
      deliveryOffersArrayNumber: 1,
      loadingDeliVeryOffers: false,
      updateDeliveryOffers: false,
      deliveryOffersID: "",
      deleteDeliveryOfferDialog: false,
      deleteDeliveryOfferItem: "",
      time: null,
      stores: [],
      selectedStore: null,
      deliveryRule: [
        (v) => !!v || "Delivery Charges is required",
        // (v) =>
        //   Number.isInteger(Number(v)) || "The value must be an integer number",
      ],
    };
  },
  methods: {
    isPriceKey(event) {
      const reg = /^-?\d*(\.\d{0,2})?$/;
      let input = event.target.value + String.fromCharCode(event.charCode);

      if (!reg.test(input)) {
        event.preventDefault();
      }
    },
    getStores() {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "store/getall")
        .then((response) => {
          if (response.status == 200) {
            this.stores = response.data.stores;
            this.selectedStore = this.stores[0];
            this.getStoreTimings();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    filteredStoreTimings() {
      // Filter timings based on selectedStore
      return this.storetimings.filter(
        (timeItem) => timeItem.store_id === this.selectedStore
      );
    },
    getDeliveryCharges() {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "setting/edit/delivery_charges")
        .then((response) => {
          if (response.status == 200) {
            this.deliveryChargesID = response.data.setting._id;
            this.delivery_charges = response.data.setting.value;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    colourChange(timeItem) {
      if (timeItem.is_open == 0) {
        color: "red";
      } else {
      }
    },
    updateTime() {
      console.log(this.selectedStore);
      console.log(this.storetimings);
      let data = {
        id: this.storetimings._id,
        type: this.storetimings.type,
        value: this.storetimings.value,
      };
      console.log(data);
      axios
        .post(process.env.VUE_APP_API_BASE_URL + "setting/update", data)
        .then((response) => {
          if (response.status == 200) {
            this.updatestoreTimings = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    submitDeliveryCharges() {
      if (this.$refs.deliVeryCharges.validate()) {
        this.deliveryCharges = true;
        let data = {
          id: this.deliveryChargesID,
          type: "delivery_charges",
          value: this.delivery_charges,
        };
        axios
          .post(process.env.VUE_APP_API_BASE_URL + "setting/update", data)
          .then((response) => {
            if (response.status == 200) {
              this.deliveryCharges = false;
              this.updateCharges = true;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    getDeliveryChargesPerMile() {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "setting/edit/delivery_charges_per_mile")
        .then((response) => {
          if (response.status == 200) {
            this.deliveryChargesPerMileID = response.data.setting._id;
            this.delivery_charges_per_mile = response.data.setting.value;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    submitDeliveryChargesPerMile() {
      if (this.$refs.deliVeryChargesPerMile.validate()) {
        this.loadingPerMile = true;
        let data = {
          id: this.deliveryChargesPerMileID,
          type: "delivery_charges_per_mile",
          value: this.delivery_charges_per_mile,
        };
        axios
          .post(process.env.VUE_APP_API_BASE_URL + "setting/update", data)
          .then((response) => {
            if (response.status == 200) {
              this.loadingPerMile = false;
              this.updateChargesPerMile = true;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    getDeliveryChargesOnRushHours() {
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL + "setting/edit/delivery_charges_on_rush_hours"
        )
        .then((response) => {
          if (response.status == 200) {
            this.deliveryChargesOnRushHoursID = response.data.setting._id;
            let totalData = [];
            let startTime = [];
            let endTime = [];
            let selectDay = [];
            let deliveryCharges = [];
            response.data.setting.value.forEach((rushHours, index) => {
              totalData.push(index);
              startTime.push(rushHours.slotStartTime);
              endTime.push(rushHours.slotEndTime);
              selectDay.push(rushHours.slotDay);
              deliveryCharges.push(rushHours.deliveryCharge);
            });
            this.deliveryChargesonRushHoursNumber = totalData;
            this.day = selectDay;
            this.deliVeryChargesOnRushHoursStart = startTime;
            this.deliVeryChargesOnRushHoursEnd = endTime;
            this.deliveryCharge = deliveryCharges;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    addMoreDeliveryChargesOnRushHours() {
      if (this.deliveryChargesonRushHoursNumber.length > 1) {
        let last = this.deliveryChargesonRushHoursNumber.length + 1;
        this.deliveryChargesonRushHoursNumber.push(last);
      } else {
        this.deliveryChargesonRushHoursNumber.push(this.arrayNumber);
        this.arrayNumber++;
      }
    },
    deleteRushHours(index) {
      this.deleteRushHoursDialog = true;
      this.deleteRushHoursItem = index;
    },
    deleteItemConfirmRushHours() {
      this.$delete(this.deliveryChargesonRushHoursNumber, this.deleteRushHoursItem);
      this.$delete(this.deliVeryChargesOnRushHoursStart, this.deleteRushHoursItem);
      this.$delete(this.deliVeryChargesOnRushHoursEnd, this.deleteRushHoursItem);
      this.$delete(this.day, this.deleteRushHoursItem);
      this.$delete(this.deliveryCharge, this.deleteRushHoursItem);
      this.deleteRushHoursDialog = false;
    },
    submitDeliveryChargesOnRushHours() {
      if (this.$refs.deliVeryChargesOnRushHours.validate()) {
        this.loadingOnRushHours = true;
        let rushHoursData = [];
        this.deliveryChargesonRushHoursNumber.forEach((rushHours, index) => {
          rushHoursData.push({
            slotStartTime: this.deliVeryChargesOnRushHoursStart[index],
            slotEndTime: this.deliVeryChargesOnRushHoursEnd[index],
            slotDay: this.day[index],
            deliveryCharge: this.deliveryCharge[index],
          });
        });
        let data = {
          id: this.deliveryChargesOnRushHoursID,
          type: "delivery_charges_on_rush_hours",
          value: rushHoursData,
        };
        axios
          .post(process.env.VUE_APP_API_BASE_URL + "setting/update", data)
          .then((response) => {
            if (response.status == 200) {
              this.loadingOnRushHours = false;
              this.updateChargesRushHours = true;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    tipaltiAuthRedirectUrl(){
      console.log("ap",process.env.VUE_APP_API_BASE_URL + "tipalti/auth_redirect");
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "tipalti/auth_redirect")
        .then((response) => {
          if (response.status == 200) {
            console.log("ap",response);
            const url = response.data.url;
            window.open(url);
            this.loading = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getRefreshTokenExpired() {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "tipalti/getRefreshtokenExpireTime")
        .then((response) => {
          if (response.status == 200) {
            this.refreshTokenExpired = response.data.is_refresh_token_expired;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getStoreTimings() {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "setting/edit/store_timings")
        .then((response) => {
          if (response.status == 200) {
            this.storetimings = response.data.setting;
            this.setting_id = response.data.setting._id;
            console.log(this.storetimings);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getDeliveryOffers() {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "setting/edit/delivery_offers")
        .then((response) => {
          if (response.status == 200) {
            this.deliveryOffersID = response.data.setting._id;
            let totalData = [];
            let minOrderValueDB = [];
            let offerInPercentage = [];
            let offerInAmount = [];
            response.data.setting.value.forEach((offers, index) => {
              totalData.push(index);
              minOrderValueDB.push(offers.minOrderValue);
              offerInPercentage.push(offers.deliveryChargeInPercentage);
              offerInAmount.push(offers.deliveryChargeInAmount);
            });
            this.deliveryOffersNumber = totalData;
            this.minOrderValue = minOrderValueDB;
            this.deliveryOffersDeliveryChargeInPercentage = offerInPercentage;
            this.deliveryOffersDeliveryChargeInAmount = offerInAmount;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    addMoreDeliveryOffers() {
      if (this.deliveryOffersNumber.length > 1) {
        let last = this.deliveryOffersNumber.length + 1;
        this.deliveryOffersNumber.push(last);
      } else {
        this.deliveryOffersNumber.push(this.deliveryOffersArrayNumber);
        this.deliveryOffersArrayNumber++;
      }
    },
    deleteDeliveryOffer(index) {
      this.deleteDeliveryOfferDialog = true;
      this.deleteDeliveryOfferItem = index;
    },
    deleteItemConfirmOffer() {
      this.$delete(this.deliveryOffersNumber, this.deleteDeliveryOfferItem);
      this.$delete(this.minOrderValue, this.deleteDeliveryOfferItem);
      this.$delete(
        this.deliveryOffersDeliveryChargeInPercentage,
        this.deleteDeliveryOfferItem
      );
      this.$delete(
        this.deliveryOffersDeliveryChargeInAmount,
        this.deleteDeliveryOfferItem
      );
      this.deleteDeliveryOfferDialog = false;
    },
    submitDeliveryOffers() {
      if (this.$refs.deliVeryOffers.validate()) {
        this.loadingDeliVeryOffers = true;
        let deliveryoffersData = [];
        this.deliveryOffersNumber.forEach((offer, index) => {
          let offerInPercentage = "";
          let offerInAmount = "";
          if (
            this.deliveryOffersDeliveryChargeInPercentage[index] == undefined ||
            this.deliveryOffersDeliveryChargeInPercentage[index] == ""
          ) {
            offerInPercentage = null;
          } else {
            offerInPercentage = this.deliveryOffersDeliveryChargeInPercentage[index];
          }
          if (
            this.deliveryOffersDeliveryChargeInAmount[index] == undefined ||
            this.deliveryOffersDeliveryChargeInAmount[index] == ""
          ) {
            offerInAmount = null;
          } else {
            offerInAmount = this.deliveryOffersDeliveryChargeInAmount[index];
          }
          deliveryoffersData.push({
            minOrderValue: parseInt(this.minOrderValue[index]),
            // deliveryChargeInPercentage: offerInPercentage,
            deliveryChargeInAmount: parseInt(offerInAmount),
          });
        });
        let data = {
          id: this.deliveryOffersID,
          type: "delivery_offers",
          value: deliveryoffersData,
        };
        axios
          .post(process.env.VUE_APP_API_BASE_URL + "setting/update", data)
          .then((response) => {
            if (response.status == 200) {
              this.loadingDeliVeryOffers = false;
              this.updateDeliveryOffers = true;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },

  mounted() {
    this.getStores();
    this.getRefreshTokenExpired();
    this.getDeliveryCharges();
    this.getDeliveryChargesPerMile();
    this.getDeliveryChargesOnRushHours();
    this.getDeliveryOffers();
    // this.colourChange();
  },
};
</script>

<style>
.theme--light.v-input--switch .v-input--switch__track {
  color: rgb(204, 50, 50);
}
.theme--light.v-input--switch .v-input--switch__thumb {
  color: rgb(204, 50, 50);
}
</style>
